import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView'
import Broadcast from '../views/Broadcast.vue'
// import ValidateCode from '@/components/ValidateCode'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/MeetingList',
    name: 'MeetingList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MeetingList.vue')
  },
  {
    path: '/AboutView',
    name: 'AboutView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/Vote',
    name: 'Vote',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Vote.vue')
  },
  {
    path: '/StartVoting',
    name: 'StartVoting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StartVoting.vue')
  },
  {
    path: '/Moments',
    name: 'Moments',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Moments.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path:"/Register",
    name:"Register",
    component:()=>import('../views/Register.vue')
  },
  {
    path:"/ResetPassword",
    name:"ResetPassword",
    component:()=>import('../views/ResetPassword.vue')
  },
  {
    path:"/Registration",
    name:"Registration",
    component:()=>import('../views/Registration.vue')
  },
  {
    path:"/getListbi",
    name:"getListbi",
    component:()=>import('../views/getListbi.vue')
  },
  {
    path:"/Broadcast",
    name:"Broadcast",
    component:()=>import('../views/Broadcast.vue'),
    //   children:[
    //   // { path: '', component: ()=>import('../views/Broadcast.vue') },
    //   {
    //     path:"",
    //     name:"AattendanRceecord",
    //     // component:()=>('../components/AattendanRceecord.vue')
    //   },
      
    //   {
    //     path:"MyResources",
    //     name:"MyResources",
    //     component:()=>('../components/MyResources.vue')
    //   },
    //   {
    //     path:"PersonalCenter",
    //     name:"PersonalCenter",
    //     component:()=>('../components/PersonalCenter.vue')
    //   },
    // ]
  },
  {
    path:"/Slide",
    name:"Slide",
    component:()=>import('../views/Slide.vue')
  },
  // {
  // path:'/ValidateCode',
  // name:'ValidateCode',
  // compone:()=>import('@/components/ValidateCode'),
  
  // },
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
