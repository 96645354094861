<template>
  <div class="root">
    <Header v-if="isMobile"></Header>
    <mobile-footer-tab></mobile-footer-tab>
    <div class="header" v-if="!isMobile">
      <div class="header_wraper">
        <img class="img" src="../assets/images/logopage.png.png" alt="" />
        <ul class="header_list">
          <router-link to="/Broadcast">
            <li class="brief">
              <span> 直播 </span>
            </li>
          </router-link>
          <router-link to="/getListbi">
            <li class="contact">
              <span @click="mycenter">我的</span>
            </li>
          </router-link>
        </ul>
        <div class="user">
          <li class="menu-item fl" style="line-height: 50px; padding: 0">
            <el-dropdown
              :show-timeout="30"
              :hide-timeout="30"
              @command="handleCommand1"
            >
              <div
                class="dropdown-content el-dropdown-link"
                style="padding: 0 15px; color: #fff"
              >
                <img
                  src="../assets/images/character.png"
                  style="width: 30px; margin-top: 20px"
                />
                <span class="text">{{ username }}</span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update">修改密码</el-dropdown-item>
                <el-dropdown-item command="signOut">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </div>
        <el-dialog title="修改密码" :visible.sync="dialogFormVisible">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item label="用户名" prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="若包含字母，请注意区分大小写"
              ></el-input>
            </el-form-item>
            <el-form-item label="旧密码" prop="pwd">
              <el-input
                type="password"
                v-model="ruleForm.pwd"
                autocomplete="off"
                placeholder="密码须为大写字母，小写字母，数字和特殊字符，其中任意三种组合，且长度在8到15之间"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="pass">
              <el-input
                type="password"
                v-model="ruleForm.pass"
                autocomplete="off"
                placeholder="请确认新密码"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
    </div>
    <!-- <div class="content">
      <div class="blane">
        <div class="search">
          <el-input
            class="inputSearch"
            placeholder="请输入内容"
            v-model="formData.meetingName"
            @keyup.enter.native="seachEnter"
          >
          </el-input>
          <div class="iconWrapper">
            <span class="icon" @click="seachEnter"
              ><i class="el-icon-search"></i
            ></span>
          </div>
        </div>
      </div>
    </div> -->
    <div>
    </div>
    <div v-if="this.formData.meetingName == ''" class="all_video">
      <div class="centerVideo">
        <div class="video_header">
          <h2 class="title">预约直播</h2>
          <div class="title_box">
            <p class="left"></p>
            <a href="#" class="title_more">MORE</a>
            <p class="right"></p>
          </div>
        </div>
        <ul class="video_list">
          <li v-for="item in vedioList" :key="item.id" @click="redirectToPolyv(item.link)">
            <div>
              <img :src="item.img" class="image" />
              <!-- <i class="el-icon-caret-right"></i> -->
              <div class="content_list">
                <div>
                  <h2 class="overflow_text">{{ item.name }}</h2>
                </div>
                <div class="bottom_clearfixs">
                  <span class="time"
                    >预约人数:
                    <!-- <i class="el-icon-user-solid"></i> -->
                    {{ item.viewsNumber }}人
                  </span>
                  <el-button
                    id=""
                    type="warning"
                    @click="ReservationButton(item)"
                    round
                    :disabled="item.isOrdered"
                    style="padding: 6px 10px; margin-bottom: 0px"
                    >预约直播</el-button
                  >
                </div>
              </div>
            </div>
          </li>
          <div v-if="this.vedioList==''">
            <el-empty description="暂无数据"></el-empty>
          </div>
        </ul>
      </div>
      <div class="centerVideo">
        <div class="video_header">
          <h2 class="title">直播中</h2>
          <div class="title_box">
            <p class="left"></p>
            <a href="#" class="title_more">MORE</a>
            <p class="right"></p>
          </div>
        </div>
        <ul class="video_list">
          <li v-for="(item, index) in todayListArray" :key="index" @click="redirectToPolyv(item.link)">
            <div>
              <img :src="item.img" class="image" />
              <!-- <i class="el-icon-caret-right"></i> -->
              <div class="content_list">
                <div>
                  <h2 class="overflow_text">{{ item.name }}</h2>
                </div>
                <div class="bottom_clearfix">
                  <span class="time">
                    <i class="el-icon-location-outline"></i>
                    {{ item.count }}
                  </span>
                  <span class="number">
                    <i class="el-icon-view">{{ item.viewsNumber }}</i>
                  </span>
                </div>
              </div>
            </div>
          </li>
          <div v-if="this.todayListArray==''">
            <el-empty description="暂无数据"></el-empty>
          </div>
        </ul>
      </div>
      <div class="centerVideo">
        <div class="video_header">
          <h2 class="title">观看回放</h2>
          <div class="title_box">
            <p class="left"></p>
            <a href="#" class="title_more">MORE</a>
            <p class="right"></p>
          </div>
        </div>
        <ul class="video_list">
          <li v-for="(item, index) in playbackList" :key="index" @click="redirectToPolyv(item.link)">
            <div>
              <img :src="item.coverImg" class="image" />
              <!-- <i class="el-icon-caret-right"></i> -->
              <div class="content_list">
                <div>
                  <h2 class="overflow_text">{{ item.name }}</h2>
                </div>
                <div class="bottom_clearfix">
                  <span class="time">
                    <i class="el-icon-location-outline"></i>
                    {{ item.publisher }}
                  </span>
                  <span class="number">
                    <i class="el-icon-view">{{ item.viewsNumber }}</i>
                  </span>
                </div>
              </div>
            </div>
          </li>
          <div v-if="this.playbackList==''">
            <el-empty description="暂无数据"></el-empty>
          </div>
        </ul>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "../components/Header.vue";
import MobileFooterTab from "../components/MobileFooterTab.vue";
export default {
  //直播
  name: "Broadcast",
  components: {
    Header,
    MobileFooterTab,
  },
  data() {
    // var namePass = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入用户名"));
    //   } else {
    //   }
    // };
    // var pwdPass = (rule, value, callback) => {
    //   // let rul = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]{8,18}$/;
    //   if (value === "") {
    //     callback(new Error("请输入旧密码"));
    //   } else {
    //     if (this.ruleForm.pwd !== "") {
    //       this.$refs.ruleForm.validateField("checkPass");
    //       console.log(4323)

    //     }
    //     // callback(new Error('密码必须由字母、数字组成，区分大小写并且密码长度为8-18位'));
    //   }
    // };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.pass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    return {
      radioDisabled: false,
      headPortrait: window.sessionStorage.getItem("headPortrait"),
      username: "",
      dialogFormVisible: false,
      ruleForm: {
        pwd: "",
        pass: "",
        name: "",
      },
      // Toactive:'1',
      isActive: "0",
      meetingId: "",
      msg: "",
      num: "0",
      index: "0",
      newvedioList: {},
      formData: {
        meetingName: "",
      },
      vedioList: [
        // 预约直播
      ],
      todayListArray: [
        // 直播中
      ],
      playbackList: [
        // 观看回放
      ],
      isCollapse: false,
      toggle: false,
      block: true,
      channelId: "",
      rules: {
        // name: [
        //   { required: true, message: "用户名不能为空", trigger: "blur" },
        //   { validator: namePass, trigger: "blur" },
        // ],
        pass: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        // pwd: [
        //   { required: true, message: "旧密码不能为空", trigger: "blur" },
        //   // { validator: pwdPass, trigger: "blur" },
        // ],
      },
      gridData: [{}],
    };
  },
  created() {
    this.vedioAll();
    this.OverAllBroadcast();
    this.liveAll();
    // this.numberList();
    // this.att()
  },
  computed: {
    ...mapState(["isMobile"]),
    PersonalCenter() {},
  },
  methods: {
    //预约人数
    numberList(channelId){
      this.$axios.get('api/poliv/realtime',{
        channelId:channelId
      }).then((res)=>{
        if(res.data.retCode==0){
          // this.todayListArray=res.data.result;
          // this.playbackList=res.data.result;
        }else{
          this.$message({
            type: "warning",
            message: res.data.msg,
          });
        }
      })
    },
     //跳转直播间
     redirectToPolyv(link) {
      console.log(link);
      this.link=link
      // 构建跳转链接
      const polyvLink = link;
      window.location.href = polyvLink;
    },
    handleCommand1(command) {
      if (command == "update") {
        this.dialogFormVisible = true;
        this.ruleForm.pwd = "";
        this.ruleForm.pass = "";
        this.ruleForm.name = "";
        this.$nextTick(() => {
          // this.$refs['ruleForm'].clearValidate()
        });
      } else {
        this.exit();
      }
    },
    exit() {
      sessionStorageNaNpxoveItem("userId");
      sessionStorageNaNpxoveItem("SET_TOKEN");
      sessionStorageNaNpxoveItem("headPortrait");
      sessionStorageNaNpxoveItem("nickName");
      this.$confirm("即将退出系统登陆，是否继续？", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.replace({
            path: "/Login",
          });
        })
        .catch(() => {});
    },
    submitForm(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        // console.log(valid)
        if (valid) {
          this.updatePass();
        }
      });
    },
    //修改密码
    updatePass() {
      this.$axios
        .post("api/user/change_user ", {
          pwd: this.ruleForm.pwd,
          pass: this.ruleForm.pass,
          // name:this.ruleForm.name,
          userId: window.sessionStorage.getItem("userId"),
        })
        .then((res) => {
          if (res && res.data.retCode == 0) {
            this.$router.push("/Login");
            this.$message({
              message: res.data.retMsg,
              type: "success",
            });
          } else if (res && res.data.retCode != 0) {
            this.$message.error(res.data.retMsg);
          }
        });
    },
    colorImg: function (img, price, index) {
      var that = this;
      that.activeColor = index;
    },
    addTitle: function (index) {
      var that = this;
      that.isActive = index;
    },

    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    seachEnter() {
      if (this.formData.meetingName === "") {
      } else {
        // this.$axios.get("api/meeting/dimMeeting", this.formData).then((res) => {
        //   if (res && res.data.retCode == 0) {
        //     this.vedioList = res.data.retData.list;
        //     this.todayListArray = res.data.retData.list;
        //     this.playbackList = res.data.retData.list;
        //     this.newvedioList = res.data.retData.list;
        //     console.log(this.vedioList);
        //   }
        // });
        this.$router.push({
          name: "MeetingList",
          params: {
            words: this.formData.meetingName,
          },
        });
      }
    },
    //预约直播列表
    vedioAll() {
      this.$axios.get("api/meeting/haveNotStarted_meeting").then((res) => {
        if (res && res.data.retCode == 0) {
          this.vedioList = res.data.retData.map((val, idx) => {
            val.isOrdered = false;
            return val;
          });
          // this.listId=res.data.retData
        } else {
          this.$message({
            type: "warning",
            message: res.data.retMsg,
          });
        }
      });
    },
    //直播中列表
    OverAllBroadcast() {
      this.$axios.get("api/meeting/hasStarted_meeting").then((res) => {
        if (res && res.data.retCode == 0) {
          this.todayListArray = res.data.retData;
          for (var i = 0; i < res.data.retData.length; i++) {
            this.numberList(res.data.retData[i].channelId);
          }
          //  console.log(222)
        } else {
          this.$message({
            type: "warning",
            message: res.data.retMsg,
          });
        }
      });
    },
    //观看回放列表
    liveAll() {
      this.$axios.get("api/meeting/playback_meeting").then((res) => {
        if (res && res.data.retCode == 0) {
          this.playbackList = res.data.retData;
          // this.channelId=res.data.retData
          for (var i = 0; i < res.data.retData.length; i++) {
            this.numberList(res.data.retData[i].channelId);
          }
        } else {
          this.$message({
            type: "warning",
            message: res.data.retMsg,
          });
        }
      });
    },
    //预约直播按钮
    ReservationButton(item) {
      console.log(item);
      this.$axios
        .post("api/user_meeting/add_reservation_meeting", {
          meetingId: item.id,
          userId: window.sessionStorage.getItem("userId"),
        })
        .then((res) => {
          item.isOrdered = true;
          if (res && res.data.retCode == 0) {
            this.$message({
              type: "success",
              message: res.data.retMsg,
            });
            //
          } else {
            this.$message({
              type: "warning",
              message: res.data.retMsg,
            });
          }
        });
    },
    addClickBtn() {},
    isCollapseNav() {
      this.isCollapse = !this.isCollapse;
      this.toggle = !this.toggle;
      this.block = !this.block;
    },
    mycenter() {},
  },
  mounted() {
    this.userId = sessionStorage.getItem("userId");
    this.nickName = sessionStorage.getItem("nickName");
    this.headPortrait = sessionStorage.getItem("headPortrait");
  },
};
</script>

<style scoped lang="less">
@import "../assets/styles/Broadcast.less";
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
