<template>
   <div class="foot">
      <div class="old">
        <span>
          <a href="http://new.crha.cn">【旧版入口】</a>
        </span>
        <span>
          <a href="#/Details?type=154">【联系我们】</a>
        </span>
      </div>
      <p class="information">
        <span class="address">地址：北京市海淀区西三环北路甲2号院北京理工大学中关村校区国防科技园7号楼8层</span>
        <span class="Telephone">联系电话：010-88928817</span>
      </p>
      <p class="record">
        <a href="https://beian.miit.gov.cn/">ICP备案号：京ICP备2022028253号-1</a>
       
      </p>
      <p class="record">
        <a target="_blank" href=" " style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="../assets/images/phone.png" style="float:left;"/><span class="record" style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px">京公网安备 11010802040621号</span></a>
      </p>
      <p class="information">
        <span class="Telephone">Copyright  2022 by https://bjyanyihui.cn/ all rights reserved</span>
      </p>
      <p class="information">
        <span class="Telephone">北京言医讯网络科技有限公司版权所有，未经书面授权禁止使用</span>
      </p>
    </div>
</template>

<script>
export default {
  name: '',
  props:{
    path:"String"
  },
  data () {
    return {
      msg: ''
    }
  },
  created(){

  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style scoped lang="less">
a {
    text-decoration: none;
    /* 去除默认的下划线 */
    color: #fff;
    /* 去除默认的颜色和点击后变化的颜色 */
}
    .foot {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background:#fff;
    background-image: url(../assets/images/foot_banner.png);

    .old {
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        margin: 10px 10px 10px;
    }
}

.information {
    margin-bottom: 10px;
    text-align: center;
    color: #fff;
    font-size: 13px;

    .address {
        margin: 0 20px;
    }
}

.record {
    margin-bottom: 10px;
    text-align: center;
    color: #fff;
    font-size: 13px;
}
</style>
