import Vue from "vue";
import Vuex, { Store } from "vuex";
import http from "../http";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userInfo: {},
    isCollapse: false, //导航 展开状态
    isMobile: false,
  },
  getters: {},
  mutations: {
    changeIsMobile: (state, isMobile) => {
      state.isMobile = isMobile;
    },
  },
  actions: {},
  modules: {},
});
export default store;
