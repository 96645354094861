import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import * as echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css';
import http from './http'
import httpJSON from './httpJson'
import axios from 'axios'
import { Base64 } from 'js-base64';
import VueLazyload from "vue-lazyload";
// import "amfe-flexible"
// import "postcss-pxtorem"
// import VueLazyload from 'vue-lazyload';
Vue.config.productionTip = false
Vue.use(ElementUI);
// Vue.use(VueLazyload);
Vue.prototype.$axios=http;
Vue.prototype.$Base64=Base64;
Vue.prototype.$axiosJSON=httpJSON;
Vue.prototype.$echarts = echarts;
// Vue.prototype.$axios = axios;
axios.defaults.baseURL='/api'
// 或者自定义配置插件
Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: require('./assets/images/back.jpg'),
  // loading: require('./assets/images/about.png'),
  attempt: 1
  })
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
