<template>
  <div class="hello">
    <header class="about" v-if="!isMobile">
      <div class="clearfix">
        <div class="logo_left">
          <img
            class="logo_img"
            height="52px"
            src="../assets/images/logo.png"
            alt=""
          />
        </div>
        <div class="logo_right">
          <div>
            <el-input
              placeholder="请输入内容"
              v-model="seachText"
              clearable
              @keyup.enter.native="seachEnter"
            >
            </el-input>
          </div>
        </div>
        <div class="login" v-if="this.usertoId == null">
          <button class="loginbar" @click="registerLogin">{{ login }}</button>
          <span class="tips">|</span>
          <button class="regsiter" @click="registers">{{ register }}</button>
        </div>
        <div class="logintwo" v-show="this.nickName !== ''">
          <el-dropdown
            style="display: flex; align-items: center"
            :show-timeout="300"
            :hide-timeout="300"
            @command="handleCommand1"
          >
            <img
              v-if="this.usertoId !== null"
              class="imgs"
              :src="this.headPortrait"
            />
            <button class="regsiterName">{{ nickName }}</button>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item command="update">修改密码</el-dropdown-item> -->
              <el-dropdown-item command="signOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div style="margin-left: 20px; margin-top: 30px">
          <el-button type="primary" round @click="toAddform"
            >创建会议</el-button
          >
        </div>
      </div>
    </header>
    <header class="about" v-if="isMobile">
      <div class="head_top">
        <div class="logo">
          <img
            class="logo_img"
            height="52px"
            src="../assets/images/logopage.png.png"
            alt=""
          />
        </div>
        <div class="user">
          <div class="login" v-if="this.usertoId == null">
            <button class="loginbar" @click="registerLogin">{{ login }}</button>
            <span class="tips">|</span>
            <button class="regsiter" @click="registers">{{ register }}</button>
          </div>
          <div class="logintwo" v-show="this.nickName !== ''">
            <el-dropdown
              style="display: flex; align-items: center"
              :show-timeout="300"
              :hide-timeout="300"
              trigger="click"
              @command="handleCommand1"
            >
              <img
                v-if="this.usertoId !== null"
                class="imgs"
                :src="this.headPortrait"
              />
              <!-- <img class="imgs" src="../assets/images/persondefault.webp"> -->
              <button class="regsiterName">{{ nickName }}</button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update">修改密码</el-dropdown-item>
                <el-dropdown-item command="signOut">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="more" @click="handleMoreBtn">
            <i class="el-icon-s-unfold"></i>
          </div>
        </div>
      </div>
      <div class="head_bot">
        <div class="search">
          <el-input
            placeholder="请输入内容"
            v-model="seachText"
            clearable
            @keyup.enter.native="seachEnter"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="seachEnter"
            ></el-button>
          </el-input>
        </div>
        <div class="create_meetting">
          <el-button type="primary" round @click="toAddform"
            >创建会议</el-button
          >
        </div>
      </div>
    </header>
    <!-- pc端头部导航菜单 -->
    <el-menu
      :default-active="activeIndex"
      class="menu-wrapper"
      mode="horizontal"
      @select="handleSelect"
      background-color="#0072FF"
      text-color="#fff"
      active-text-color="#02B9FD"
      v-if="!isMobile"
      router
    >
      <el-menu-item index="/">
        <span slot="title">首页</span>
      </el-menu-item>

      <el-submenu index="/">
        <template slot="title">
          <span>会议服务</span>
        </template>
        <el-menu-item index="/MeetingList">会议列表</el-menu-item>
        <el-menu-item index="/Broadcast">直播</el-menu-item>
      </el-submenu>
      <el-menu-item index="/Moments">
        <span slot="title">创新转化</span>
      </el-menu-item>

      <el-submenu index="/1">
        <template slot="title">
          <span>企业文化</span>
        </template>
        <el-menu-item index="/AboutView">关于我们</el-menu-item>
      </el-submenu>
      <!-- <el-menu-item v-for="item in navList" :key="item.id" :index="item.path">
        <template slot="title">
          <span> {{ item.navItem }}</span>
        </template>
      </el-menu-item> -->
    </el-menu>
    <!-- 手机端头部导航菜单 -->
    <el-menu
      :default-active="activeIndex"
      class="menu-wrapper"
      :class="{ menu_active: isShowMore }"
      mode="vertical"
      @select="handleSelect"
      background-color="#0072FF"
      text-color="#fff"
      active-text-color="#02B9FD"
      v-if="isMobile"
      router
    >
      <el-menu-item index="/">
        <span slot="title">首页</span>
      </el-menu-item>

      <el-submenu index="/1">
        <template slot="title">
          <span>会议服务</span>
        </template>
        <el-menu-item index="/MeetingList">会议列表</el-menu-item>
        <el-menu-item index="/Broadcast">直播</el-menu-item>
      </el-submenu>
      <el-menu-item index="/Moments">
        <span slot="title">创新转化</span>
      </el-menu-item>

      <el-submenu index="/1">
        <template slot="title">
          <span>企业文化</span>
        </template>
        <el-menu-item index="/AboutView">关于我们</el-menu-item>
      </el-submenu>

      <p class="close">
        <i class="close_btn el-icon-s-fold" @click="handleCloseBtn"></i>
      </p>
      <!-- <el-menu-item v-for="item in navList" :key="item.id" :index="item.path">
        <template slot="title">
          <span> {{ item.navItem }}</span>
        </template>
      </el-menu-item> -->
    </el-menu>
    <el-dialog title="创建会议" :visible.sync="disabledFlag">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="直播名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="主持人名称" prop="publisher">
          <el-input v-model="ruleForm.publisher"></el-input>
        </el-form-item>
        <el-form-item label="直播模板" prop="model">
          <el-radio-group
            v-model="ruleForm.model"
            size="small"
            @change="addModeSelect(ruleForm.model)"
          >
            <el-radio-button
              v-model="ruleForm.model.alone"
              label="alone"
              @click="dialogFormVisible = true"
              >推拉流</el-radio-button
            >
            <el-radio-button v-model="ruleForm.model.ppt" label="ppt"
              >普通直播</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-dialog
          title="填写地址"
          :visible.sync="dialogFormVisible"
          :append-to-body="true"
          width="30%"
        >
          <el-form :model="ruleForm">
            <el-form-item label="拉流地址" label-width="120px">
              <el-input v-model="ruleForm.pulUrl"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <el-form-item label="直播延迟" prop="pureRtcEnabled">
          <el-radio-group v-model="ruleForm.pureRtcEnabled" size="small">
            <el-radio-button v-model="ruleForm.pureRtcEnabled.Y" label="Y"
              >无延迟</el-radio-button
            >
            <el-radio-button v-model="ruleForm.pureRtcEnabled.N" label="N"
              >普通直播</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="讲师登录密码" prop="channelPasswd">
          <el-input v-model="ruleForm.channelPasswd"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="link">
          <el-input v-model="ruleForm.link"></el-input>
        </el-form-item>
        <el-form-item label="会议时间" prop="startTime">
          <el-date-picker
            v-model="ruleForm.startTime"
            type="datetime"
            placeholder="选择会议时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="连麦人数" prop="linkMicLimit">
          <el-input-number
            v-model="ruleForm.linkMicLimit"
            @change="handleChange"
            :min="1"
            :max="16"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="简介" prop="desc">
          <el-input v-model="ruleForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="封面" prop="splashImg">
          <el-upload
            v-model="ruleForm.splashImg"
            class="upload-demo"
            list-type="picture-card"
            :action="apiUrl + '/api/poliv/upload_splashImage'"
            :on-success="handleAvatarSuccess"
            :on-change="idenImghandleChange"
            accept=".JPG, .png, .jpeg"
            ref="toBack"
            multiple
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="图标" prop="coverImg">
          <el-upload
            v-model="ruleForm.coverImg"
            class="upload-demo"
            list-type="picture-card"
            :action="apiUrl + '/api/poliv/upload_coverImage'"
            :on-success="iconAvatarSuccess"
            :on-change="iconhandleChange"
            accept=".JPG, .png, .jpeg"
            ref="toBack"
            multiple
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item> -->
        <el-form-item class="form_btns">
          <el-button type="primary" @click="codeHandle('ruleForm')"
            >立即创建</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: {
    path: String,
  },
  data() {
    return {
      userId: "",
      isCollapse: true,
      disabledFlag: false,
      formLabelWidth: "240px",
      seachText: "",
      usertoId: "",
      nickName: window.sessionStorage.getItem("nickName"),
      headPortrait: window.sessionStorage.getItem("headPortrait"),
      login: "登录",
      register: "注册",
      activeIndex:
        this.$route.path == "/getListbi" ? "/Broadcast" : this.$route.path,
      ruleForm: {
        name: "",
        model: {
          alone: "",
          ppt: "",
        },
        pureRtcEnabled: {
          Y: "",
          N: "",
        },
        channelPasswd: "",
        startTime: "",
        linkMicLimit: "",
        splashImg: "",
        // coverImg: "",
        desc: "",
        publisher: "",
        pulUrl: "",
        link:"",
      },
      dialogFormVisible: false,
      navList: [
        // { name: "0", navItem: "首页", path: "/" },
        // // { name: "1", navItem: "产品&服务", path: "/Registration" },
        // { name: "2", navItem: "会议列表", path: "/MeetingList" },
        // { name: "3", navItem: "精彩瞬间", path: "/Moments" },
        // { name: "4", navItem: "直播", path: "/Broadcast" },
        // { name: "5", navItem: "关于我们", path: "/AboutView" },
      ],
      rules: {
        name: [
          { required: true, message: "请输入会议名称", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        publisher: [
          { required: true, message: "请输入主持人名称", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入会议名称", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        // seminarHostPassword: [
        //   { required: true, message: "开播者密码", trigger: "blur" },
        // ],
        channelPasswd: [
          { required: true, message: "参会人密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: "blur",
          },
        ],
        startTime: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        linkMicLimit: [
          { required: true, message: "请填写活动形式", trigger: "blur" },
        ],
        link: [
          { required: true, message: "请填写链接", trigger: "blur" },
        ],
        model: [{ required: true, message: "请填写直播模板", trigger: "blur" }],
        pureRtcEnabled: [
          { required: true, message: "请填写直播延迟", trigger: "blur" },
        ],
        desc: [{ required: true, message: "请填写简介", trigger: "blur" }],
        splashImg: [{ required: true, message: "请上传封面", trigger: "blur" }],
        // coverImg: [{ required: true, message: "请上传图标", trigger: "blur" }],
      },
      isShowMore: false, // 移动端顶部更多按钮状态
    };
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  created() {
    this.activePath = window.sessionStorage.getItem(this.apiUrl + "activePath");
    this.apiUrl = window.location.protocol + "//" + window.location.host;
    this.userId = window.sessionStorage.getItem("userId");
  },
  methods: {
    // 移动端更多按钮
    handleMoreBtn() {
      this.isShowMore = true;
    },
    // 移动端关闭按钮
    handleCloseBtn() {
      this.isShowMore = false;
    },
    //监听直播模块
    addModeSelect(value) {
      if (value === "alone") {
        this.dialogFormVisible = true;
      }
    },
    activeItem(path) {
      window.sessionStorage.setItem("activePath", path);
      this.activePath = path;
      console.log(this.activePath);
    },
    toAddform() {
      if (this.SET_TOKEN == null) {
        window.location.href = "../Login";
      } else {
        this.disabledFlag = true;
      }
    },
    codeHandle(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitForm();
        }
        // this.$refs[formName].resetFields();
      });
    },
    submitForm() {
      this.disabledFlag = true;
      this.$axios
        .post("api/poliv/insert_meeting", {
          name: this.ruleForm.name,
          model: this.ruleForm.model,
          pureRtcEnabled: this.ruleForm.pureRtcEnabled,
          channelPasswd: this.ruleForm.channelPasswd,
          startTime: this.ruleForm.startTime,
          // .toISOString()
          // .replace(/T/g, " ")
          // .replace(/\.[\d]{3}Z/, ""),
          linkMicLimit: this.ruleForm.linkMicLimit,
          userId: window.sessionStorage.getItem("userId"),
          splashImg: this.ruleForm.splashImg.retData,
          // coverImg: this.ruleForm.coverImg.retData[0],
          desc: this.ruleForm.desc,
          publisher: this.ruleForm.publisher,
          pulUrl: this.ruleForm.pulUrl,
          link: this.ruleForm.link,
        })
        .then((res) => {
          if (res && res.data.retCode == 10) {
            this.disabledFlag = false;
            this.$message({
              type: "error",
              message: res.data.retMsg,
            });
          } else {
            this.$message({
              type: "success",
              message: "创建成功，请耐心等待管理员的审核",
            });
          }
        });
    },
    handleAvatarSuccess(response, file, fileList) {
      this.splashImg = file.url; //保存临时图片
      this.ruleForm.splashImg = response; //保存到提交表单
    },
    idenImghandleChange() {
      this.$refs.ruleForm.validateField("splashImg"); //对部分表单字段进行校验的方法，也就是手动进行表单验证（关键代码）
    },

    // iconAvatarSuccess(response, file, fileList) {
    //   this.coverImg = file.url; //保存临时图片
    //   this.ruleForm.coverImg = response; //保存到提交表单
    // },
    // iconhandleChange() {
    //   this.$refs.ruleForm.validateField("coverImg"); //对部分表单字段进行校验的方法，也就是手动进行表单验证（关键代码）
    // },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleChange() {},
    handleCommand1(command) {
      if (command == "signOut") {
        this.exit();
      }
      if (command == "update") {
        console.log(command);
      }
    },
    exit() {
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("SET_TOKEN");
      sessionStorage.removeItem("headPortrait");
      sessionStorage.removeItem("nickName");
      this.$confirm("即将退出系统登陆，是否继续？", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.replace({
            path: "/Login",
          });
        })
        .catch(() => {});
    },
    //input 回车搜索
    seachEnter(e) {
      if (this.seachText === "") {
        return;
      } else {
        // 把input带着，发请求获取有关联的数据并呈现在页面中
        // console.log("带着关键字交给后端搜索", this.seachText);
        if (this.$route.path != "/MeetingList") {
          this.$router.push({
            name: "MeetingList",
            params: {
              words: this.seachText,
            },
          });
        }
      }
    },
    handleSelect(key, keyPath) {
      // 移动端 关闭左侧选项卡
      this.isShowMore = false;
      // setTimeout(() => {
      //   if (e == 0) {
      //     window.location.href = "/";
      //   } else if (e == 1) {
      //     window.location.href = "../Registration";
      //   } else if (e == 2) {
      //     window.location.href = "../MeetingList";
      //   } else if (e == 3) {
      //     window.location.href = "../Moments";
      //   } else if (e == 4) {
      //     window.location.href = "../Broadcast";
      //   } else if (e == 5) {
      //     window.location.href = "../AboutView";
      //   }
      // }, 300);
    },
    registerLogin() {
      this.$router.push("/Login");
    },
    registers() {
      this.$router.push("/Register");
    },
  },
  mounted() {
    this.userId = sessionStorage.getItem("userId");
    this.SET_TOKEN = sessionStorage.getItem("SET_TOKEN");
    this.nickName = sessionStorage.getItem("nickName");
    this.headPortrait = sessionStorage.getItem("headPortrait");
    // var that=this
    this.usertoId = this.userId;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@media screen and (min-width: 1200px) {
  .about {
    display: flex;
    padding-bottom: 10px;
    padding: 24px 0;
    background-color: #fff;
  }

  .clearfix {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .logo_left {
    float: left;
    margin-right: 150px;
    margin-top: 20px;
  }

  .logo_img {
    height: 40px;
    max-width: 100%;
    /* max-height: 107%; */
    min-height: 70px;
  }

  .logo_right {
    display: flex;
    line-height: 100px;
  }
  .login {
    display: flex;
    margin-left: 30px;
    line-height: 28px;
    height: 30px;
    margin-top: 37px;
  }
  .logintwo {
    display: flex;
    margin-left: 20px;
  }
  .loginbar {
    width: 50px;
    color: #999999;
    font-size: 14px;
    border: none;
    background: none;
  }
  .imgs {
    width: 50px;
    height: 50px;
  }
  .tips {
    margin-left: 10px;
    color: #999999;
    /* font-size: 14px; */
  }
  /deep/ .el-menu.el-menu--horizontal {
    border: none;
    border-bottom: none;
  }
  .regsiter {
    width: 50px;
    margin-left: 10px;
    color: #999999;
    font-size: 14px;
    border: none;
    background: none;
  }
  .regsiterName {
    color: #999999;
    font-size: 14px;
    border: none;
    background: none;
  }
  /deep/ .menu-wrapper {
    border-bottom: none;
    text-align: center;
    display: flex;

    .iconfont {
      width: 30px;
      color: #ffffff;
      font-size: 20px;
      // margin: 10px;
    }
    .menu-item {
      span {
        font-size: 15px;
      }

      &.is-active,
      &:hover {
        background-color: #131313 !important;
        color: #479ae1 !important;

        i {
          color: #479ae1 !important;
        }
      }
    }

    .el-submenu__title {
      > span {
        font-size: 15px;
      }
    }

    .el-submenu__title:hover {
      color: #479ae1 !important;

      i,
      span {
        color: #479ae1 !important;
      }
    }

    .el-submenu,
    .el-menu-item {
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .hello {
    font-size: 16px;
    height: 90px;
    width: 100%;
  }
  .about {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 10;
    width: 100%;
    box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.08);
  }
  .head_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    height: 50px;
    .logo {
      height: 5rem;
      .logo_img {
        height: 100%;
      }
    }
    .user {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .login {
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          padding: 0;
          margin: 0;
          border: none;
          background: none;
          font-size: 14px;
          flex: 0 0 auto;
        }
        .loginbar {
          color: #999;
        }
        .tips {
          color: #999;
          margin: 0 1rem;
        }
        .regsiter {
          color: #999;
        }
      }
      .logintwo {
        margin-right: 1rem;
        img {
          width: 30px;
          border-radius: 50%;
        }
        button {
          border: none;
          background: none;
        }
      }
      .more {
        display: flex;
        align-items: center;
        i {
          color: #999;
          font-style: normal;
          font-size: 2rem;
        }
      }
    }
  }
  .head_bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    height: 40px;
    .search {
      display: flex;
      align-items: center;
      height: 100%;
      flex: 2 1 auto;
      .el-input {
        height: 30px;
        margin-right: 1rem;
        width: 100%;
        /deep/ .el-input__inner {
          height: 30px;
          border-radius: 0.4rem;
          line-height: 30px;
          padding: 0 1rem;
          font-size: 14px;
        }
        /deep/ .el-input__suffix {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .el-input-group--append {
        /deep/ .el-input__inner {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .el-button {
        height: 30px;
        /deep/ span {
          font-size: 14px;
        }
        &.is-round {
          border-radius: 2rem;
          padding: 0 1.2rem;
        }
      }
    }
    .create_meetting {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      flex: 1 0 auto;
      .el-button {
        width: 100%;
        height: 30px;
        margin-left: 1rem;
        line-height: 1;
        padding: 0 2rem;
        /deep/ span {
          font-size: 14px;
        }
      }
    }
  }
  /deep/.el-submenu__title {
    display: flex;
  }
  .menu-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0;
    z-index: 11;
    box-sizing: border-box;
    border: none;
    transition: left 0.3s linear;
    padding-top: 50px;
    .el-menu-item {
      height: 50px;
      line-height: 50px;
      // padding-left: 2rem !important;
      margin: 0;
      text-align: left;
      span {
        font-size: 16px;
      }
    }
    .close {
      font-style: normal;
      height: 50px;
      padding: 0 2rem;
      box-sizing: border-box;
      width: 100%;
      line-height: 50px;
      margin: 0;
      text-align: right;
      position: absolute;
      top: 0;
      left: 0;
      .close_btn {
        font-style: normal;
        color: #fff;
        font-size: 2rem;
      }
    }
  }
  .menu_active {
    left: 0;
  }
  /deep/ .el-dialog__wrapper {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-dialog {
      margin: 0 !important;
      width: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .el-dialog__header {
        flex: 0 0 auto;
        padding: 20px 2rem;
      }
      .el-dialog__body {
        flex: 1 1 auto;
        overflow: scroll;
        padding: 0 1rem;
        box-sizing: border-box;
        width: 100%;
        .el-form {
          .el-form-item {
            display: flex;
            justify-content: center;
            .el-form-item__content {
              margin-left: 0 !important;
              flex: 1 1 2rem;
              .el-date-editor {
                width: 100%;
                box-sizing: border-box;
              }
              .el-upload__tip {
                line-height: 20px;
              }
            }
            &.form_btns {
              .el-form-item__content {
                display: flex;
                justify-content: center;
              }
            }
            .idCard2 {
              text-align: center;
              .el-upload {
                width: 100px;
                height: 100px;
                position: relative;
                border-radius: 50%;
                overflow: hidden;
                img {
                  position: absolute;
                  width: 100px;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, 50%);
                }
              }
            }
          }
        }
      }
      .el-dialog__footer {
        flex: 0 0 auto;
        padding: 10px 2rem 20px;
        .dialog-footer {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
h3 {
  /* margin: 40px 0 0; */
}
ul {
  /* list-style-type: none; */
  /* padding: 0; */
}
li {
  /* display: inline-block; */
  /* margin: 0 10px; */
}
a {
  /* color: #42b983; */
}
</style>
<style>
/* 移动端 $confirm 组件宽度过大 */
@media screen and (max-width: 768px) {
  .el-message-box {
    width: auto !important;
  }
}
</style>
