<template>
  <div class="footer_tab" v-if="isMobile">
    <ul class="tab_list">
      <li class="brief" :class="{ 'actived': $route.name == 'Broadcast' }">
        <router-link to="/Broadcast">
          <i class="el-icon-video-camera"></i>
          <span> 直播 </span>
        </router-link>
      </li>
      <li class="contact" :class="{ 'actived': $route.name == 'getListbi' }">
        <router-link to="/getListbi">
          <i class="el-icon-user"></i>
          <span>我的</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "MobileFooterTab",
  data() {
    return {};
  },
  computed:{
    ...mapState(['isMobile'])
  }
};
</script>
<style lang="less">
*{
    padding: 0;
    margin: 0;
}
li{
    list-style: none;
}
a{
    text-decoration: none;
}
.footer_tab {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  height: 60px;
  padding: 0 1.5rem;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #eee;
  z-index: 10;
  .tab_list {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    .brief,
    .contact {
      flex: 1 0 auto;
      height: 100%;
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 10rem;
        min-width: 50px;
        margin: 0 auto;
        height: 100%;
        > i {
          font-size: 20px;
          color: #999;
        }
        > span {
          font-size: 16px;
          color: #999;
        }
      }
    }
    .actived {
      a > i,
      a > span {
        color: #409eff;
      }
    }
  }
}
</style>
