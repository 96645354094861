<template>
  <div class="conMmain">
    <Header></Header>
    <div class="main">
      <div class="main_img">
        <img class="img" :src="this.rotatbigImg" />
      </div>
    </div>
    <div class="floor">
      <div class="block">
        <el-carousel trigger="click" :height="carouselHeight + 'px'">
          <el-carousel-item
            v-for="(item, index) in RotationChatList"
            :key="index"
          >
            <img
              :src="item.slideshowImg"
              alt=""
              class="carousel_image_type"
              @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="floor_right">
        <div class="floor_header">
          <h2 class="nm_title">最新会议</h2>
          <p @click="getMore" style="cursor: pointer">更多 ></p>
        </div>
        <div
          class="floor_list"
          ref="contlist"
          @mouseover="stopScroll"
          @mouseout="startScroll"
        >
          <ul class="infoList" :style="{ top: tableTop + 'px' }">
            <li
              class="news"
              v-for="(item, index) in tableList"
              :key="index"
              :index="item"
              @click="LatestMeeting(item.link)"
            >
              <a class="tex_lin">{{ item.text }}</a>
              <a class="date">{{ item.textTime }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="home_introduction">
      <div>
        <img
        src='../assets/images/companyTitle.png' 
          alt=""
          style="width: 35%; margin-bottom: 1rem; margin-top: 1rem"
        />
      </div>
      <div>
        <img
        src='../assets/images/公司简介正文.png'
          alt=""
          style="width: 100%"
        />
      </div>
    </div>

    <div class="home_bans">
      <div>
        <img
          src="../assets/images/核心业务标题.png"
          alt=""
          style="width: 35%; margin-bottom: 1rem; margin-top: 1rem"
        />
      </div>
      <div class="center">
        <img src="../assets/images/核心业务.png" alt="" style="width: 100%" />
      </div>
    </div>
    <div class="home_center">
      <img
        src="../assets/images/banner.png"
        alt=""
        style="width: 100%; margin: 0 auto"
      />
    </div>
    <div class="centerVideo">
      <div class="video_header">
        <h2 class="title">视频中心</h2>
        <div class="title_box">
          <p class="left"></p>
          <p @click="getMore" class="title_more">MORE</p>
          <p class="right"></p>
        </div>
      </div>
      <ul class="video_list">
        <li
          v-for="(item, index) in vedioList"
          :key="index"
          @click="redirectToPolyv(item.link)"
        >
          <div>
            <img :src="item.coverImg" class="image" />
            <!-- <i class="el-icon-caret-right"></i> -->
            <div class="content">
              <div>
                <h2 class="overflow_text">{{ item.name }}</h2>
              </div>
              <div class="bottom_clearfix">
                <span class="time">
                  <i class="el-icon-user-solid"></i>
                  {{ item.publisher }}
                </span>
                <span class="number">
                  <i class="el-icon-view">{{ item.viewsNumber }}人</i>
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- <div class="recommendVideo">
      <div class="video_header">
        <h2 class="title">会议中心</h2>
        <div class="title_box">
          <p class="left"></p>
          <a href="#" class="title_more">MORE</a>
          <p class="right"></p>
        </div>
      </div>
      <ul class="video_list">
        <li v-for="(item, index) in vedioList" :key="index">
          <div>
            <img :src="item.img" class="image">
            <div class="content">
              <div>
                <h2 class="overflow_text">{{item.name}}</h2>
              </div>
              <div class="bottom_clearfix">
                <span class="time">
                  <i class="el-icon-time"></i>
                  {{item.publisher}}
                </span>
                <span class="number">
                  <i class="el-icon-view">{{item.viewsNumber}}</i>
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div> -->
    <Foot></Foot>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Foot from "@/components/Foot.vue";
import { mapState } from "vuex";
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: "HomeView",
  components: {
    Header,
    Foot,
  },
  data() {
    return {
      // psdimg:'../../assets/images/companyTitle.png', 
      // palyUrl:"../../assets/images/公司简介正文.png",
      carouselHeight: 0, // el-carousel组件自适应高度
      link: "",
      seachText: "",
      login: "登录",
      register: "注册",
      activeIndex2: "0",
      showFlag: true,
      tableTimer: null,
      tableTop: 0,
      tableTimerInterval: 100, //向上滚动 1px 所需要的时间，越小越快
      tableListSize: 0,
      visibleSize: 6, //容器内可视最大完整行数
      scrollW: 0, //记录滚动到哪了
      navList: [
        { name: "0", navItem: "首页" },
        { name: "1", navItem: "产品&服务" },
        { name: "2", navItem: "会议列表" },
        { name: "3", navItem: "精彩瞬间" },
        { name: "4", navItem: "直播" },
        { name: "5", navItem: "关于我们" },
      ],
      rotatbigImg: "",
      RotationChatList: [],
      tableList: [
      ],
      vedioList: [],
      meetingList: [],
      currentDate: new Date(),
    };
  },

  computed: {
    ...mapState(["isMobile"]),
    // 每行的实际高度（包含margin-top/bottom,border等）
    lineHeight: {
      get() {
        console.log(this.isMobile ? 50 : 42);
        return this.isMobile ? 50 : 42;
      },
    },
  },
  created() {
    this.getVideoCenter();
    this.getRotationChat();
  },
  beforeMount() {},
  mounted() {},
  methods: {
    // 根据图片大小自适应el-carousel高度
    imgLoad(e) {
      this.carouselHeight = e.target.height;
    },
    LatestMeeting(){
      
    },
    //跳转直播间
    redirectToPolyv(link) {
      console.log(link);
      this.link = link;
      // 构建跳转链接
      const polyvLink = link;
      window.location.href = polyvLink;
    },
    //首页轮播
    getRotationChat() {
      this.$axios.get("api/slideshow/homepage_all").then((res) => {
        if (res && res.data.retCode == 0) {
          this.RotationChatList = res.data.retData.small;
          this.tableList = res.data.retData.textContent;
          this.rotatbigImg = res.data.retData.big[0].slideshowImg;
          console.log(this.rotatbigImg);
          this.tableActionFun();
        } else {
          this.$message({
            type: "warning",
            message: res.data.retMsg,
          });
        }
      });
    },
    //首页视频列表
    getVideoCenter() {
      this.$axios.get("api/meeting/playback_meeting").then((res) => {
        if (res && res.data.retCode == 0) {
          this.vedioList = res.data.retData;
        } else {
          this.$message({
            type: "warning",
            message: res.data.retMsg,
          });
        }
      });
    },
    getMore() {
      this.$router.push("./MeetingList");
    },
    tableActionFun() {
      this.tableListSize = this.tableList.length;
      //下面的visibleSize是可视范围内能够显示的最大完整数据条数
      if (this.tableListSize > this.visibleSize) {
        this.tableList = this.tableList.concat(
          this.tableList.slice(0, this.visibleSize)
        );
        this.tableTimerFun(); //列表滚动方法
      } else {
        this.fillTableList(); //无需滚动时的数据填充方法，如果没必要，可以删掉cs
      }
    },
    //列表滚动方法
    tableTimerFun() {
      this.tableTimer = setInterval(() => {
        if (
          -this.tableTop <
          (this.tableList.length - this.visibleSize) * this.lineHeight
        ) {
          this.tableTop -= 1;
        } else {
          this.tableTop = 0;
        }
      }, this.tableTimerInterval);
    },
    //当数据过少时，不触发自动轮播事件，并填充没有数据的行，参数根据实际情况修改即可
    fillTableList() {
      var addLength = this.visibleSize - this.tableListSize;
      for (var i = 0; i < addLength; i++) {
        this.tableList.push({
          text: "",
          textTime: "",
        });
      }
    },
    //鼠标悬停，停止滚动
    stopScroll() {
      var target = this.$refs.contlist;
      clearInterval(this.tableTimer);
    },
    //鼠标移开 ，接着滚动
    startScroll() {
      this.tableListSize = this.tableList.length;
      //下面的visibleSize是可视范围内能够显示的最大完整数据条数
      if (this.tableListSize < this.visibleSize) {
      } else {
        var target = this.$refs.contlist;
        this.scrollW = target.offsetTop; // 移开时记录向上移动的距离
        this.tableTimerFun();
      }
    },
  },
};
</script>
<style scoped lang="less">
@import "../assets/styles/common.less";
@media screen and (max-width: 400px) {
  body {
    margin: 0;
    padding: 0;
    background-color: red;
  }
  // .center{
  //   width: 80%;
  //   margin: 0 auto;
  //   background-image: url("../assets/images/核心业务.png");
  // }
}
</style>
