import Vue from "vue";
import axios from 'axios';
import { Base64 } from "js-base64";
import qs from "qs"
import {
    Loading,
    Message,
    MessageBox
} from 'element-ui'
import router
    from "./router";


// let baseURL;
// if (process.env.NODE_ENV == 'development') {
//     baseURL = '/';
// } else {
//     baseURL = process.env.API_URL;
// }

const http = axios.create({
    // baseURL, //  process.env.API_URL
    timeout: 1800000,
    transformRequest: [function (data) { //在向服务器发送前，修改请求数据
        // data = qs.stringify(data)
        data = JSON.stringify(data)
        return data;
    }]
});

// 请求拦截器
http.interceptors.request.use(config => {
    let token = sessionStorage.getItem('SET_TOKEN');
    if (config.method == 'get') {
        if (!config.params.noToken) {
            if (token) {
                config.headers.Authorization = token
            }
        }
        // delete config.params.noToken;

        // if (Object.keys(config.params).indexOf('aiplatformsign') != -1) {
        //     delete config.params.aiplatformsign
        // }
        // if (config['url'].indexOf("?") != -1) {//get方法传参时如果蚕食拼在接口后面就会走这个方法；
        //     let str = config.url.split('?')[1];
        //     let arr = str.split('&')
        //     arr.map((item, index) => {
        //         let key = item.split('=')[0];
        //         let val = item.split('=')[1];
        //         config.params[key] = val
        //     })
        //     config.url = config.url.split('?')[0];
        // }
        // let uuid = getRandomCode();
        // config.params['aiplatformRid'] = uuid;
        // config.headers['aiplatformRid'] = uuid;
        // config.headers['aiplatformsign'] = getSign(config.params)

    } else if (config.method == 'post' || config.method == 'put') {
        // if (!config.data.noToken) {
        //     if (token) {
        //         config.headers.Authorization = token
        //     }
        // }
        // let uuid = getRandomCode();
        // config.data['aiplatformRid'] = uuid;
        // config.headers['aiplatformRid'] = uuid;
        // delete config.data.noToken;
        // config.headers['aiplatformsign'] = getSign(config.data)

    } else if (config.method == 'delete') {
        // let uuid = getRandomCode();
        // config.headers['aiplatformRid'] = uuid;
        // if (config['url'].indexOf("?") != -1) {
        //     //url使用问号携带参数的
        //     config.headers['aiplatformsign'] = paramsStrSort([config.url.split('?')[1], 'uuid=' + uuid].join('&'));
        //     config['url'] = config.url + '&uuid=' + uuid;
        // } else {
        //     //url为空的
        //     config.headers['aiplatformsign'] = paramsStrSort(['uuid=' + uuid].join('&'));
        //     config['url'] = config.url + '?uuid=' + uuid;
        // }
    }


    // 开发环境下，如果请求是 post,put,patch,则打印数据体，方便调试
    if (process.env.NODE_ENV === 'development') {
        const { method } = config;
        if (method === 'post' || method === 'put' || method === 'patch') {

        }
    }
    return config;
}, error => {
    Message.error({
        message: '请求失败'
    });
    return Promise.reject(error);
});

// 响应拦截器
// let tokenFlag = false
// let num403 = 0
// let tokenFlagError = false
// let num403error = 0
http.interceptors.response.use(res => {
    // if (res.data.return_code == 403 || res.data.code == 401 || res.data.code == 403) {
    //     tokenFlag = true
    //     num403++

    // } else {
    //     tokenFlag = false
    // }


    // if (tokenFlag) {
    //     if (num403 == 1) {
    //         MessageBox.confirm('认证失败，请重新登录?', '提示', {
    //             confirmButtonText: '确定',
    //             showCancelButton: false,
    //             showClose: false,
    //             closeOnClickModal: false,
    //             type: 'warning'
    //         }).then(() => {
    //             sessionStorage.removeItem('SET_TOKEN');
    //             sessionStorage.removeItem('userName');
    //             router.replace({
    //                 name: 'login'
    //             })
    //             num403 = 0
    //             return

    //         })
    //     }

    // }
    // if (res.headers.sys_log_filename) {
    //     return res;
    // } else {
    //     return res.data;
    // }
    return res;
}, error => {

    // if (error.response) {
    //     if (error.response.status == 401 || error.response.status == 403) {
    //         tokenFlagError = true
    //         num403error++

    //     } else {
    //         tokenFlagError = false
    //     }
    // }


    // if (tokenFlagError) {
    //     if (num403error == 1) {
    //         // MessageBox.confirm(error.response.data.msg, '提示', {
    //         MessageBox.confirm('认证失败，请重新登录', '提示', {
    //             confirmButtonText: '确定',
    //             showCancelButton: false,
    //             showClose: false,
    //             closeOnClickModal: false,
    //             type: 'warning'
    //         }).then(() => {
    //             sessionStorage.removeItem('SET_TOKEN');
    //             sessionStorage.removeItem('userName');
    //             router.replace({
    //                 name: 'login'
    //             })
    //             num403error = 0
    //             return

    //         })
    //     }
    // }
    // if (error && error.response) {
      return Promise.reject(error);
    // }
});


//封装get接口
// params={} 是设置默认值
function get(url, params = {}) {
    // params.noToken = params.noToken || "";
    return http({
        url: url,
        method: 'get',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        params
    })
}
//封装post请求
function post(url, data = {}) {

    //默认配sendObject置
    // data.noToken = data.noToken || "";
    // data.ifAuthoritySystem = data.ifAuthoritySystem || '';
    let sendObject = {
        url: url,
        method: "post",
        headers:{
            // Accept:'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: data,
    };
    // sendObject.data = JSON.stringify(data);
    return http(sendObject)
}
//封装put请求
function put(url, data = {}) {
    //默认配置
    // data.noToken = data.noToken || "";
    let sendObject = {
        url: url,
        method: "put",
        headers:{
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: data,
    };
    // sendObject.data = JSON.stringify(data);
    return http(sendObject)
}
//封装delet请求
function delet(url, data = {}) {
    //默认配置
    let token = sessionStorage.getItem('SET_TOKEN')
    return http({
        url: url,
        method: 'delete',
        headers: {
            Authorization: token,
        },
        data: data
    })
}
export default {
    get,
    post,
    put,
    delet,

}
